<!--
 * @Description: 固定费用支出配置
 * @Author: 琢磨先生
 * @Date: 2022-08-17 03:25:16
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-11-13 10:26:40
-->


<template>
  <el-card class="box">
    <el-form :model="query" ref="query" :inline="true">
      <el-form-item label="房间">
        <el-cascader
          :options="huxing_list"
          v-model="query.storeIds"
          clearable
          style="width: 260px"
          filterable
        ></el-cascader>
      </el-form-item>
      <el-form-item label="费用项">
        <el-select v-model="query.itemIds" multiple clearable style="width: 300px">
          <el-option v-for="item in expenseList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="订单类型">
        <el-select v-model="query.order_type" clearable style="width: 160px">
          <el-option
            v-for="item in enums.order_types"
            :key="item.value"
            :label="item.text"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>-->
      <el-form-item label="月份">
        <el-date-picker
          v-model="query.date"
          type="month"
          format="YYYY年MM月"
          value-format="YYYY-MM-01"
        />
      </el-form-item>
      <el-form-item label="删除?">
        <el-select v-model="query.is_del" placeholder :clearable="true" style="width:100px;">
          <el-option label="正常" value="false"></el-option>
          <el-option label="已删除" value="true"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search">查询</el-button>
      </el-form-item>
    </el-form>
    <div>
      <el-button
        type="primary"
        size="small"
        @click="onEdit()"
        round
        icon="plus"
        v-if="is_can_edit"
      >新增</el-button>
      <el-button
        type="danger"
        size="small"
        @click="onBatchDelete"
        round
        icon="delete"
        :loading="deleting"
        v-power="'seller/v1/expenseconfig/del/batch'"
        :disabled="selection_list.length <= 0"
      >批量删除</el-button>
    </div>
  </el-card>
  <el-card class="box data">
    <el-table
      v-loading="loading"
      :data="tableData.data"
      border
      row-key="id"
      :row-style="handleRowStyle"
      @selection-change="selectionChange"
    >
      <el-table-column type="selection" width="55" reserve-selection />
      <!-- <el-table-column
        label="订单类型"
        prop="order_type_text"
        width="120"
      ></el-table-column>-->
      <el-table-column label="类型" prop="type_text" width="120"></el-table-column>
      <el-table-column label="计费模式" prop="mode_text" width="120"></el-table-column>
      <el-table-column label="费用项" prop="expense_item.name" width="150"></el-table-column>
      <el-table-column label="房号" prop="store.house_no" width="180"></el-table-column>
      <el-table-column label="金额" width="150">
        <template #default="scope">￥{{ scope.row.decimal_amount }}</template>
      </el-table-column>
      <el-table-column label="实际成本" width="150">
        <template #default="scope">￥{{ scope.row.decimal_cost_amount }}</template>
      </el-table-column>
      <el-table-column label="起止月份" width="220">
        <template #default="scope">
            <span v-if="scope.row.is_long_time">不限</span>
            <span v-else>{{ scope.row.start_month }}~{{ scope.row.end_month }}</span>
        </template>
      </el-table-column>
      <el-table-column label="停用" width="150">
        <template #default="scope">
          <el-tag type="danger" v-if="scope.row.is_stop">停用</el-tag>
          <el-tag type="success" v-else>正常</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="描述" prop="description" min-width="300"></el-table-column>
      <el-table-column label="创建时间" prop="create_at" width="200"></el-table-column>
      <el-table-column label="删除？" width="180">
        <template #default="scope">{{scope.row.is_deleted?'已删除':''}} / {{ scope.row.deleted_at }}</template>
      </el-table-column>
      <el-table-column label="操作" width="140" fixed="right">
        <template #default="scope">
          <el-button
            type="primary"
            link
            size="small"
            @click="onEdit(scope.row)"
            v-if="!scope.row.is_deleted && !scope.row.is_system"
          >修改</el-button>
          <el-button
            type="primary"
            link icon="view"
            size="small"
            @click="log_primary_key = scope.row.id"
            v-if="is_can_edit"
            >查看日志</el-button
          >
          <el-popconfirm
            title="去定要删除？"
            @confirm="onDelete(scope.row)"
            v-if="!scope.row.is_deleted && is_can_del && !scope.row.is_system"
          >
            <template #reference>
              <el-button type="danger" link size="small" class="text-danger">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
      v-if="tableData.counts > 0"
    ></el-pagination>
  </el-card>

  <el-dialog
    :title="title"
    v-model="visibleDialog"
    width="800px"
    draggable
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <!-- <el-form-item label="订单类型" prop="order_type">
        <el-radio-group v-model="form.order_type">
          <el-radio
            :label="item.value"
            v-for="item in enums.order_types"
            :key="item.value"
            >{{ item.text }}</el-radio
          > 
        </el-radio-group>
      </el-form-item>-->
      <el-form-item label="类型" prop="type">
        <el-radio-group v-model="form.type">
          <el-radio :label="0">月固定费用</el-radio>
          <el-radio :label="5">订单费用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="计费模式" prop="mode">
        <el-radio-group v-model="form.mode">
          <el-radio :label="0">一次</el-radio>
          <el-radio :label="10">每天</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="费用项" prop="item_id">
        <el-select v-model="form.item_id">
          <el-option v-for="item in expenseList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="房源房号" prop="storeIds">
        <el-cascader
          :options="huxing_list"
          v-model="form.storeIds"
          style="width: 300px"
          filterable
          :props="{ multiple: true }"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="业主占比" v-if="form.type==0">
        <el-col :span="6">
          <el-input v-model="form.decimal_ratio" placeholder>
            <template #suffix>%</template>
          </el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="费用总额(元)" prop="decimal_amount">
        <el-col :span="8">
          <el-input v-model="form.decimal_amount" placeholder>
            <template #prefix>￥</template>
          </el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="成本(元)" prop="decimal_cost_amount">
        <el-col :span="8">
          <el-input v-model="form.decimal_cost_amount" placeholder>
            <template #prefix>￥</template>
          </el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="数量" prop="number">
        <el-col :span="6">
          <el-input v-model="form.number" placeholder></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="单价" prop="decimal_unit_price">
        <el-col :span="8">
          <el-input v-model="form.decimal_unit_price" placeholder>
            <template #prefix>￥</template>
          </el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="不限日期" prop="is_long_time">
        <el-switch v-model="form.is_long_time" @change="longTimeChange"></el-switch>
      </el-form-item>

      <el-form-item label="日期选择" prop="dates" v-if="!form.is_long_time">
        <el-col :span="12">
          <el-date-picker
            v-model="form.dates"
            type="monthrange"
            start-placeholder="开始月份"
            value-format="YYYY-MM-01"
            format="YYYY年MM月"
            end-placeholder="结束月份"
            :clearable="false"
            unlink-panels
            @change="datePickerChange"
          />
        </el-col>
      </el-form-item>
      <el-form-item label="描述">
        <el-col :span="16">
          <el-input v-model="form.description" placeholder type="textarea"></el-input>
        </el-col>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving">确定</el-button>
    </template>
  </el-dialog>
  <operate-log code="6002" :primaryKey="log_primary_key" @close="log_primary_key=''"></operate-log>

</template>

<script>
import expense_api from "@/http/expense_api";
import huxing_api from "@/http/huxing_api";
// import common_api from "@/http/common_api";
import OperateLog from '@/views/components/operate_log_dra';


export default {
  components: { 
    OperateLog
  },
  data() {
    return {
      loading: false,
      saving: false,
      visibleDialog: false,
      //批量删除中
      deleting: false,
      title: "",
      form: {},
      //
      enums: {},
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      tableData: {
        counts: 0,
      },
      rules: {
        // order_type: [
        //   {
        //     required: true,
        //     message: "必选",
        //     trigger: "blur",
        //   },
        // ],
        type: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        mode: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        decimal_amount: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
        ],
        item_id: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        storeIds: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        dates: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        // start_date: [
        //   {
        //     required: true,
        //     message: "必选",
        //     trigger: "blur",
        //   },
        // ],
        // end_date: [
        //   {
        //     required: true,
        //     message: "必选",
        //     trigger: "blur",
        //   },
        // ],
      },
      //费用项目
      expenseList: [],
      storeList: [],
      huxing_list: [],
      //选择的数据
      selection_list: [],
      //可编辑
      is_can_edit: false,
      is_can_del: false,
      log_primary_key:'',//日志查看主键id
    };
  },
  created() {
    // common_api.get_enums().then((res) => {
    //   if (res.code == 0) {
    //     this.enums = res.data;
    //   }
    // });
    expense_api.get_expenses().then((res) => {
      if (res.code == 0) {
        this.expenseList = res.data;
      }
    });

    huxing_api.get_cascader().then((res) => {
      if (res.code == 0) {
        this.huxing_list = [];
        res.data.forEach((x) => {
          var model = {
            value: x.id,
            label: x.name,
            children: [],
          };
          x.store_list.forEach((item) => {
            model.children.push({
              value: item.id,
              label: item.house_no,
            });
          });
          this.huxing_list.push(model);
        });
      }
    });
    this.loadData();

    this.is_can_edit = this.$power("seller/v1/expenseConfig/edit");
    this.is_can_del = this.$power("seller/v1/expenseConfig/del");
  },
  methods: {
    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadData();
      }
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },

    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.current = null;
      this.query.store_id = "";
      if (this.query.storeIds) {
        this.query.store_id =
          this.query.storeIds[this.query.storeIds.length - 1];
      }
      this.$http.post(`seller/v1/expenseConfig`, this.query).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },

    /**
     * 不限日期选择
     */
    longTimeChange(val) {
      console.log(val);
      if (val) {
        delete this.rules.dates;
      } else {
        this.rules.dates = [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ];
      }
    },

    /**
     *
     */
    onEdit(item) {
      this.title = item ? "修改" : "新增";
      this.form = Object.assign(
        {
          type: 0,
          mode: 0,
          order_type: 0,
        },
        item
      );

      if (item) {
        this.form.dates = [item.start_date, item.end_date];
        this.form.storeIds = [item.store.group_id, item.store.id];
        delete this.form.store;
        delete this.form.expense_item;
        delete this.form.del_emp;
      }

      this.visibleDialog = true;
    },

    /**
     * 日期选择更改
     */
    datePickerChange(values) {
      this.form.start_date = values[0];
      this.form.end_date = values[1];
    },

    /**
     * 提交保存
     */
    onSubmit() {
      this.form.store_ids = [];
      if (this.form.storeIds.length > 0) {
        this.form.storeIds.forEach((x) => {
          this.form.store_ids.push(x[x.length - 1]);
        });
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("/seller/v1/expenseConfig/edit", this.form)
            .then((res) => {
              this.saving = false;
              if (res.code == 0) {
                this.$message.success(res.msg);
                this.loadData();
                this.visibleDialog = false;
              }
            })
            .cath(() => {
              this.saving = false;
            });
        }
      });
    },
    /**
     * 删除
     * @param {*} item
     */
    onDelete(item) {
      this.$http
        .get("/seller/v1/expenseConfig/del?id=" + item.id)
        .then((res) => {
          if (res.code == 0) {
            this.$message({ type: "success", message: res.msg });
            this.loadData();
          }
        });
    },

    /**
     * 表格选择更改
     * @param {*} nodes
     */
    selectionChange(nodes) {
      this.selection_list = nodes;
    },
    /**
     * 批量删除
     */
    onBatchDelete() {
      this.$confirm("您确定要删除所有选择项？", "温馨提示")
        .then(() => {
          // this.$loading()
          var ids = this.selection_list.map((x) => x.id);
          this.deleting = true;
          this.$http
            .post("seller/v1/expenseConfig/del/batch", ids)
            .then((res) => {
              if (res.code == 0) {
                this.selection_list = [];
                this.onSearch();
              }
            })
            .finally(() => (this.deleting = false));
        })
        .catch(() => {});
    },

    /**
     *
     */
    handleRowStyle(item) {
      if (item.row.is_deleted) {
        return "color:#f56c6c;";
      }
    },
  },
};
</script>

<style scoped>
</style>